<template>
  <el-dialog title="资金转移" :visible.sync="dialogVisible" width="79%" :before-close="handleClose">
    <el-form v-model="shiftFrom" label-width="100px">
      <!--  水费  -->
      <el-row :gutter="21">
        <el-col :span="7">
          <el-form-item label="转出存款编码" width="120px">
            <el-select v-model="shiftOutList" filterable placeholder="请选择" @change="shiftOut">
              <el-option
                v-for="item in shiftOutOption"
                :key="item.id"
                :label="item.subbranch"
                :value="{label:item.bankCode,value:item.id,text:item.subbranch,noPutMoney:item.noPutMoney,
                depositedIncomeMoney:item.depositedIncomeMoney,
                bankType:item.bankType}"
              ></el-option>
            </el-select>
            {{this.shiftOutList.label}}
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="可转存款金额" width="120px">
            <el-input width="20px" v-model="shiftFrom.shiftOutBeMoney" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="转移后金额" width="120px">
            <el-input width="20px" v-model="shiftFrom.ShiftOutAfMoney" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="21">
        <el-col :span="7">
          <el-form-item label="备注" width="120px">
            <el-input width="20px" v-model="shiftFrom.remark"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="本次交易金额" width="120px">
            <el-input-number width="20px" v-model="shiftFrom.money" @change="changeMoney" />
          </el-form-item>
        </el-col>
      </el-row>
      <!--  水费  -->
      <el-row :gutter="21">
        <el-col :span="7">
          <el-form-item label="转入存款编码" width="120px">
            <el-select v-model="shiftInList" filterable placeholder="请选择" @change="shiftIn">
              <el-option
                v-for="item in shiftInOption"
                :key="item.id"
                :label="item.subbranch"
                :value="{label:item.bankCode,value:item.id,text:item.subbranch,noPutMoney:item.noPutMoney,
                depositedIncomeMoney:item.depositedIncomeMoney,
                bankType:item.bankType}"
              ></el-option>
            </el-select>
            {{this.shiftInList.label}}
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="转入交易金额" width="120px">
            <el-input width="20px" v-model="shiftFrom.shiftInBeMoney" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="交易后的金额" width="120px">
            <el-input width="20px" v-model="shiftFrom.shiftInAfMoney" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="shiftButton">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { formatDate } from "@/plugins/data.js";
import { selectBankInfoList, shiftMoney } from "@/api/capital/bank/bank";
export default {
  name: "shiftMoney",
  data() {
    return {
      dialogVisible: false,
      shiftOutOption: [],
      shiftOutList: {},
      shiftInOption: [],
      shiftInList: {},
      shiftFrom: {
        shiftOutId: null,
        money: null,
        remark: null,
        shiftInId: null
      }
    };
  },
  methods: {
    //打开弹窗
    show() {
      this.dialogVisible = true;
      this.selectBankInfoButton();
    },
    selectBankInfoButton() {
      selectBankInfoList().then(res => {
        this.shiftOutOption = res.result;
        this.shiftInOption = res.result;
      });
    },
    ok() {
      this.$emit("closeModal", false);
      this.$Message.info("Clicked ok");
    },
    cancel() {
      this.$emit("closeModal", false);
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    shiftOut() {
      if (this.shiftOutList.bankType == 1) {
        this.shiftFrom.shiftOutBeMoney = this.shiftOutList.noPutMoney;
      } else {
        this.shiftFrom.shiftOutBeMoney = this.shiftOutList.depositedIncomeMoney;
      }
    },
    shiftIn() {
      if (this.shiftInList.bankType == 1) {
        this.shiftFrom.shiftInBeMoney = this.shiftInList.noPutMoney;
      } else {
        this.shiftFrom.shiftInBeMoney = this.shiftInList.depositedIncomeMoney;
      }
    },
    changeMoney() {
      if (this.shiftFrom.money > this.shiftFrom.shiftOutBeMoney) {
        this.shiftFrom.money = null;
        return this.$message.error("没那么多的金额转出");
      }
      this.shiftFrom.ShiftOutAfMoney =
        this.shiftFrom.shiftOutBeMoney - this.shiftFrom.money;
      this.shiftFrom.shiftInAfMoney =
        this.shiftFrom.shiftInBeMoney + this.shiftFrom.money;
    },
    shiftButton() {
      this.shiftFrom.shiftInId = this.shiftInList.value;
      this.shiftFrom.shiftOutId = this.shiftOutList.value;
      shiftMoney(this.shiftFrom).then(res => {
        console.log(this.res);
        this.dialogVisible=true;
        this.$parent.getBankList()
          return this.$message.success(res.message);
      });
    }
  }
};
</script>
<style lang="less" scoped>
</style> 